<template>
  <div class="cmp-edit-project-list">  

    <div class="full-screen-fader edit-project flex-column justify-content-center">
      <div class="utlz-container">
        <div class="utlz-row">
          <div class="utlz-col-lg-12">
            <span class="close-button" @click="closeEditProject()">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
            <div class="modal-content edit-proj-modal utlz-row">
              <div class="utlz-col-12">
                <div class="proj-desc">                  
                  <utlz-edit-name 
                    :id="project.ProjectCode"
                    :defaultValue="project.ProjectDescription"
                    :placeholder="projectDescPh"
                    :valueKey="'projectDescription'"
                    @valueChanged="updateName($event)"
                  ></utlz-edit-name>
                  <div class="delete delete-project">
                    <span @click="deleteProject(project)" class="delete fa fa-trash"></span>
                  </div>
                </div>                
              </div>

              <div class="utlz-col-12">
                <div v-for="(location, index) in project.Locations" :key="location.LocationCode + index" class="proj-location" :class="{ changed: location.PositionChanged }">
                  <div class="location-row" v-if="!location.IsDeleted">
                    <div class="location-name">
                      <utlz-edit-name 
                        :defaultValue="location.LocationDescription"
                        :id="location.LocationCode"
                        :placeholder="locationDescPh"
                        :valueKey="'locationDescription'"
                        @valueChanged="updateName($event)"
                      ></utlz-edit-name>
                      <span @click="deleteLocation(location)" class="delete-location">
                        <i class="delete fa fa-trash"></i>
                      </span>

                      <template v-if="project.Locations.length > 1">
                        <div class="sort-loc">
                          <div class="sort-btns">
                              <span class="sort-up loc" @click="moveLocation({ direction: 'up', locationCode: location.LocationCode })">
                                <i class="fa fa-caret-up"></i>
                              </span>
                              <span class="sort-down loc" @click="moveLocation({ direction: 'down', locationCode: location.LocationCode })">
                                <i class="fa fa-caret-down"></i>
                              </span>
                            </div>
                        </div>
                      </template>


                    </div>
                    <div class="location-products">

                      <div v-for="product in location.Products" :key="product.DbId">
                        <div class="product-row" v-if="!product.IsDeleted" :class="{ changed: product.PositionChanged }">
                          <div class="prod-img">
                            <img :src="product.ImageUrl"/>
                          </div>
                          <div class="prod-desc">
                            <p class="prod-desc">{{ product.Description }}</p>
                            <p class="prod-code">{{ product.Id }}</p>
                          </div>
                          <div class="quantity">
                            <utlz-quantity-buttons
                              :initialQuantity="product.Quantity"
                              :allowZeroQuantity="false"
                              :quantityFactor="1"
                              @quantityChanged="quantityChanged($event, {locationId: location.LocationCode, prodDbId: product.DbId })">
                            </utlz-quantity-buttons>
                          </div>                          
                          <div class="prod-comment">
                            <input v-if="true" :placeholder="phOrderComment" :value="product.Comment" type="text" @change="commentChanged($event, location, product)" class="order-comment">
                          </div>
                          <div class="delete">
                            <span @click="deleteProductFromLocation({ locationCode: location.LocationCode, productCode: product.Id })" class="delete fa fa-trash"></span>
                          </div>

                          <template v-if="location.Products.length > 1">
                            <div class="sort-btns">
                              <span class="sort-up" @click="moveProduct({ direction: 'up', dbId: product.DbId, location: location })">
                                <i class="fa fa-caret-up"></i>
                              </span>
                              <span class="sort-down" @click="moveProduct({ direction: 'down', dbId: product.DbId, location: location })">
                                <i class="fa fa-caret-down"></i>
                              </span>
                            </div>
                          </template>

                        </div>                        
                      </div>

                      
                      <div class="add-product-row">
                        <a class="icon-link add-product-link" @click="showAddProductToLocation(location)">
                          <i class="fa fa-plus-circle"></i>
                          <span v-translation="{ code: 'button_add_prod_toloc', type: 'button'}"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="utlz-col-12">
                <a @click="addNewLocation()" v-translation="{ code: 'button_add_location', type: 'button' }" class="btn-u blue-btn add-location-btn"></a>                
              </div>
              <div class="buttons-row utlz-row">
                <a @click="saveProject()" v-translation="{ code: 'button_save_proj_changes', type: 'button' }" class="btn-u save-changes"></a>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div v-if="showSearchProduct" class="full-screen-fader edit-project add-product flex-column justify-content-center">
      <div class="utlz-container">
        <div class="utlz-row">
          <div class="utlz-col-lg-12">
            <span class="close-button" @click="closeSearchProduct()">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
            <div class="modal-content add-product-modal-content utlz-row">
              <utlz-search-product
                @addProductToLocation="addProductToLocation($event)"
              ></utlz-search-product>
            </div>
          </div>  
        </div>
      </div>
    </div>

    <transition name="fadein">
      <div class="cmp-prompt" v-if="showDeleteLocationPrompt">
        <div class="full-screen-fader flex-column justify-content-center">
          <div class="utlz-container">
            <div class="utlz-row">
                <div class="utlz-col-lg-8 utlz-offset-lg-2">
                <span class="close-button">
                    <i class="fa fa-times-circle" aria-hidden="true" @click="showDeleteLocationPrompt = false"></i>
                </span>
                <div class="prompt-content">
                    <h2>
                        <span v-translation="{ type: 'title', code: 'title_del_loc_prompt' }"></span>
                    </h2>
                    <div>
                        <span v-translation="{type: 'text', code: 'text_prompt_del_loc'}"></span>
                    </div>
                    <div class="action-btns full-width-buttons-mobile">
                        <a @click="cancelDeleteLocation()" v-translation="{ type: 'button', code: 'button_prompt_cancel'}" class="button cancel"></a>
                        <a @click="confirmDeleteLocation()" v-translation="{ type: 'button', code: 'button_prompt_confirm'}" class="button theme-primary confirm"></a>
                    </div>
                </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  </transition>

  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import axios from 'axios';
import EditName from './EditName.vue';
import SearchProduct from './SearchProduct.vue';
import QuantityButtons from './../../../../src/components/webshop/order-product/blocks/QuantityButtons.vue';
import { getProductInformation } from './../../../../src/services/product-service';

export default {
  components: {
    'utlz-edit-name': EditName,
    'utlz-search-product': SearchProduct,
    'utlz-quantity-buttons': QuantityButtons
  },
  props: {
    project: { type: Object, required: true }
  }, 
  data() {
    return {
      showSearchProduct: false,
      editedLocation: null,
      projectDescPh: window.vue.translations.label.label_ph_proj_desc,
      locationDescPh: window.vue.translations.label.label_ph_loc_desc,
      showDeleteLocationPrompt: false,
      locationToDelete: null,      
      phOrderComment: window.vue.translations.label.label_orderline_comment_ph
    }
  },
  computed: {    
    ...mapGetters(['bespokeEndpoints'])
  },
  methods: {    
    moveLocation({ direction, locationCode }) {
      let locationIndexWithinProject = this.project.Locations.findIndex(x => x.LocationCode === locationCode);
      var newIndex = direction == 'up' ? locationIndexWithinProject - 1 : locationIndexWithinProject + 1;
      let locationToMove = this.project.Locations.splice(locationIndexWithinProject, 1)[0];
      locationToMove.PositionChanged = true;
      setTimeout(() => {
        locationToMove.PositionChanged = false;
      }, 1250);
      this.project.Locations.splice(newIndex, 0, locationToMove);      
    },
    moveProduct({ direction, dbId, location }) {
      let productIndexWithinLocation = location.Products.findIndex(x => x.DbId === dbId);
      console.log(productIndexWithinLocation);
      var newIndex = direction == 'up' ? productIndexWithinLocation - 1 : productIndexWithinLocation + 1;
      let productToMove = location.Products.splice(productIndexWithinLocation, 1)[0];
      // productToMove.PositionChanged = true;
      
      location.Products.splice(newIndex, 0, productToMove);
      // setTimeout(() => {
        
      //   productToMove.PositionChanged = false;
        
      // }, 200);
    },
    commentChanged(event, location, product) {
        var comment = event.target.value;
        var index = location.Products.findIndex(x => x.DbId === product.DbId);
        location.Products[index].Comment = comment;
    },
    closeEditProject() {
      this.$emit('closeEditProject')
    },
    updateName({ id, valueKey, value }) {
      if (valueKey === 'projectDescription') {
        this.project.ProjectDescription = value;
      } else if (valueKey === 'locationDescription') {
        const locationIndex = this.project.Locations.findIndex(location => location.LocationCode === id);
        this.project.Locations[locationIndex].LocationDescription = value; 
      }
    },
    showAddProductToLocation(location) {
      this.editedLocation = location;
      this.showSearchProduct = true;
    },
    closeSearchProduct() {
      this.showSearchProduct = false;
      this.editedLocation = null;
    },
    addProductToLocation(newLocationProduct) {
      const locationIndex = this.project.Locations.findIndex(location => location.LocationCode === this.editedLocation.LocationCode);
      this.project.Locations[locationIndex].Products.push(newLocationProduct);
    },
    deleteProductFromLocation({ locationCode, productCode }) {
      const locationIndex = this.project.Locations.findIndex(location => location.LocationCode === locationCode);
      const productIndex = this.project.Locations[locationIndex].Products.findIndex(product => product.Id === productCode);
      let prod = this.project.Locations[locationIndex].Products[productIndex];
      if (prod.DbId.startsWith('TEMP')) {
        this.project.Locations[locationIndex].Products.splice(productIndex, 1);
      } else {
        prod.IsDeleted = true;
      }
    },
    deleteLocation(location) {
      this.locationToDelete = location;
      this.showDeleteLocationPrompt = true;
    },
    deleteProject(project) {
      this.$emit('deleteProject', project);
    },
    confirmDeleteLocation() {
      const index = this.project.Locations.findIndex(location => location.LocationCode === this.locationToDelete.LocationCode);
      if (this.locationToDelete.LocationCode.startsWith("TEMP")) {
        this.project.Locations.splice(index, 1);
      } else {
        this.project.Locations[index].IsDeleted = true;
      }
      this.showDeleteLocationPrompt = false;
      this.locationToDelete = null;
    },
    cancelDeleteLocation() {
      this.locationToDelete = null;
      this.showDeleteLocationPrompt = false;
    },
    addNewLocation() {
      var locationGuid = 'TEMP' + crypto.randomUUID();
      var newLocation = {
        LocationCode: locationGuid,
        LocationDescription: window.vue.translations.label.label_new_location_name,
        Products: []
      };
      this.project.Locations.push(newLocation);
    },  
    quantityChanged({quantity}, {locationId, prodDbId}) {
      const locationIndex = this.project.Locations.findIndex(location => location.LocationCode === locationId);
      const productIndex = this.project.Locations[locationIndex].Products.findIndex(product => product.DbId === prodDbId);
      this.project.Locations[locationIndex].Products[productIndex].Quantity = quantity;
    },
    enrichLocationProducts(prodCodes) {
      var payload = { 
        prodCodes: prodCodes,
        getStock: false, 
        getPrices: false,
        getCustomerLists: false,
        getSeoInformation: false
      };

      getProductInformation(payload).then(res => {
        const productsWithInformation = res.products;
        productsWithInformation.forEach(prodInfo => {

          this.project.Locations.forEach(location => {
            location.Products.forEach(prod => {
              if (prod.Id === prodInfo.id) {
                prod.Description = prodInfo.shortDescription;
                prod.ImageUrl = prodInfo.imageUrl ? prodInfo.imageUrl : '/documents/productimages/not-available-small.jpg';
              }
            });
          });
        });
      });
    },
    saveProject() {

      // The entire project is sent as one object, so fix the row orders for all the elements
      this.project.Locations.forEach((location, index) => {
        if (!location.IsDeleted) {
          location.RowOrder = index;
        }

        location.Products.forEach((product, prodIndex) => {
          if (!product.IsDeleted) {
            product.RowOrder = prodIndex;
          }
        });
      });

      this.$emit('saveProject', JSON.parse(JSON.stringify(this.project)));
    }
  },
  created() {
    var prodCodes = [];

    this.project.Locations.forEach(location => {
      location.Products.forEach(prod => {
        prodCodes.push(prod.Id);
      });
    });
    this.enrichLocationProducts(prodCodes);
  }
}
</script>