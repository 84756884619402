import axios from 'axios';
import { Product } from 'src/models/product.model';

function defaultProductInformationEndpoint () {
  const getters = window.vuexStore.$store.getters;
  return `${getters.productInformationEndpoint}/${getters.clientCode}?language=${getters.language}`;
}

function defaultStockEndpoint () {
  const getters = window.vuexStore.$store.getters;
  return getters.productStockEndpoint;
}

function defaultProductPriceEndpoint () {
  const getters = window.vuexStore.$store.getters;
  return getters.productPriceEndpoint + 'prices/' + getters.clientCode;
}

function defaultCustomerListEndpoint () {
  const getters = window.vuexStore.$store.getters;
  return `${getters.getProductsInListsEndpoint}?debtorId=${getters.customerId}`;
}

function defaultProductSeoEndpoint () {
  const getters = window.vuexStore.$store.getters;
  return getters.seoInformationEndpoint + '/GetProductImageInformation';
}

export function getProductInformation ({ prodCodes, endpoint, getStock = true, getPrices = true, getCustomerLists = true, getSeoInformation = true }) {
  const productInformationEndpoint = endpoint || defaultProductInformationEndpoint();

  return new Promise((resolve, reject) => {
    axios.post(productInformationEndpoint, prodCodes).then(res => {
      if (res.data) {
        const products = res.data.map(product => new Product(product));
        const retrievedStock = getStock ? getProductStock(products) : false;
        const retrievedPrices = getPrices ? getProductPrice(products) : false;
        const retrievedCustomerLists = getCustomerLists ? getProductCustomerLists(products) : false;
        const retrievedSeo = getSeoInformation ? getProductSeoInformation(products) : false;

        resolve({
          products: products,
          retrievedStock: retrievedStock,
          retrievedPrices: retrievedPrices,
          retrievedCustomerLists: retrievedCustomerLists,
          retrievedSeo: retrievedSeo
        });
      }
    });
  });
}

export function getProductStock (products, endpoint) {
  const getters = window.vuexStore.$store.getters;

  return new Promise((resolve, reject) => {
    if (getters.showStock) {
      const stockEndpoint = endpoint || defaultStockEndpoint();
      let productStockFetched = 0;

      products.forEach(product => {
        axios.post(stockEndpoint, { 'productCode': product.id })
          .then(res => {
            product.setStock(res.data.d);
            productStockFetched++;
            if (productStockFetched === products.length) {
              resolve(true);
            }
          });
      });
    } else {
      products.forEach(product => { product.setStock({ stockTotal: 0 }); })
      resolve(true);
    }
  })
}

export function getProductPrice (products, endpoint) {
  const getters = window.vuexStore.$store.getters;
  return new Promise((resolve, reject) => {
    if (getters.showPrices) {
      let productPricesFetched = 0;

      let priceRequestWrapper = {};
      priceRequestWrapper.CustomerId = getters.userLoggedOn ? getters.customerId : '';
      priceRequestWrapper.Pricelist = getters.customerPriceList;
      priceRequestWrapper.Products = products.map(prod => {
        let ret = {};
        ret.ProductId = prod.id;
        ret.ProductGroup = prod.discountGroup;

        ret.ProductUnit = '';
        if (prod.units !== undefined && prod.units !== null) {
          ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
        }
        return ret;
      });

      let pricingEndpoint = endpoint || defaultProductPriceEndpoint();

      axios.post(pricingEndpoint, priceRequestWrapper)
        .then(res => {
          res.data.forEach(price => {
            let product = products[products.findIndex(x => x.id === price.productId)];

            const pricesObj = [];
            Object.keys(price.volumes).forEach(key => {
              pricesObj.push({
                price: price.volumes[key].price,
                basePrice: price.volumes[key].basePrice,
                isSalesAction: price.volumes[key].isSalesAction,
                quantity: Math.round(parseInt(key))
              });
            });

            product.setPrices(pricesObj);
            productPricesFetched++;
            if (productPricesFetched === products.length) {
              resolve(true);
            }
          });
        });
    } else {
      resolve(true)
    }
  });
}

export function getProductCustomerLists (products, endpoint) {
  const getters = window.vuexStore.$store.getters;

  return new Promise((resolve, reject) => {
    if (getters.showFavorites) {
      const customerListEndpoint = endpoint || defaultCustomerListEndpoint();
      axios.post(customerListEndpoint, products.map(product => product.id))
        .then(res => {
          Object.keys(res.data).forEach(prodCode => {
            let filteredProduct = products[products.findIndex(product => product.id === prodCode)];
            if (filteredProduct !== undefined) {
              filteredProduct.setCustomerLists(res.data[prodCode]);
            }
          });
          resolve(true);
        });
    } else {
      resolve(true);
    }
  });
}

export function getProductSeoInformation (products, endpoint) {
  return new Promise((resolve, reject) => {
    const seoEndpoint = endpoint || defaultProductSeoEndpoint();
    let productCodes = products.map(prod => prod.id);

    axios.post(seoEndpoint, productCodes)
      .then(res => {
        res.data.forEach(SeoOject => {
          let product = products[products.findIndex(x => x.id === SeoOject.ProductId)];
          product.setSeoInformation(SeoOject);
        });
        resolve(true);
      });
  });
}


// BESPOKE VLINT-94
export function getProductPriceBespoke (products) {
  const getters = window.vuexStore.$store.getters;
  return new Promise((resolve, reject) => {
    let productPricesFetched = 0;

    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = getters.userLoggedOn ? getters.customerId : '';
    priceRequestWrapper.Pricelist = getters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let pricingEndpoint = defaultProductPriceEndpoint();

    axios.post(pricingEndpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          productPricesFetched++;
          if (productPricesFetched === products.length) {
            resolve(products);
          }
        });
      });
  });
}
// END BESPOKE
