<template>
  <div class="orderline-row">

    <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell">
      <a :href="orderLine.product.targetUrl">
        <img :src="orderLine.product.imageUrl" />
      </a>
    </div>

    <div class="prod-desc orderline-cell">
      <a :href="orderLine.product.targetUrl">
        <h3 class="prod-title body-text">{{ orderLine.product.shortDescription }}</h3>
      </a>
        <utlz-order-line-comment
          :orderLine="orderLine"
        ></utlz-order-line-comment>
    </div>

    <div class="order-product orderline-cell">
      <utlz-order-product
        v-if="orderLine.product.stock !== null"
        :product="orderLine.product"
        :initialQuantity="orderLine.quantity"
        :initialUnitCode="orderLine.unitCode"
        :useOrderMode="false"
        :totalOrderLinesQuantity="orderLine.totalOrderLinesQuantity"
        @quantityChanged="quantityChanged($event)"
        @unitsChanged="unitsChanged($event, orderLine)"
      ></utlz-order-product>
    </div>

    <template v-if="showPrices">
      <div class="prod-price orderline-cell" v-if="orderLine.product.prices.length">
        <utlz-product-price
          :prices="orderLine.product.prices[0]"
        ></utlz-product-price>
      </div>
      <div v-else class="stock-status-wrapper text-placeholder pulse">
        <span class="loader"></span>
      </div>
    </template>

    <div class="prod-total orderline-cell">
      <span>{{ orderLine.totals.totalPrice }}</span>
    </div>

    <div class="delete-prod orderline-cell">
      <span class="close-button small grey" @click="deleteOrderLine()">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </span>
    </div>

  </div>
</template>

<script>
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import OrderLineComment from 'src/components/webshop/order-product/blocks/OrderLineComment.vue';

export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-order-line-comment': OrderLineComment
  },
  props: {
    showPrices: { type: Boolean, required: true },
    hideShoppingCartImages: { type: Boolean, required: true},
    orderLine: { type: Object, required: true },
  },
  methods: {
    deleteOrderLine() {
      this.$emit('deleteOrderLine', this.orderLine);
    },
    quantityChanged(event) {
      this.$emit('quantityChanged', event);
    }
  }
}
</script>

<style>

</style>